@import '../../../styles/customMediaQueries.css';

.title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.mainTitle {
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  width: 80%;
  margin: 0 auto;

  @media (--viewportLarge) {
    font-size: 40px;
    line-height: 48px;
    width: 100%;
  }
}

.holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 0 80px;

  @media (--viewportLarge) {
    background: url('./images/join-bg.png') center center no-repeat;
    margin-top: 84px;
    padding: 100px 0 80px;
  }
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 85%;
  margin-top: 8px;

  @media (--viewportLarge) {
    max-width: 55%;
  }
}

.holderLeft {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e9e9f7;
  padding: 40px 0;

  @media (--viewportLarge) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 0 40px;
    align-items: flex-start;
    text-align: left;
    background-color: transparent;
  }
}

.imageHolder {
  display: flex;
  width: 100%;
  max-width: 100%;

  & img {
    width: 100%;
    max-width: 100%;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.holderRight {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fef4fa;
  padding: 40px 0;

  @media (--viewportLarge) {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
    align-items: flex-end;
    padding: 0 40px 0 0;
    background-color: transparent;
  }
}

.buttonLeft {
  width: 168px;
  height: 46px;
  border-radius: 20px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #9191d6;
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #9191d6;
    box-shadow: none;
    text-decoration: none;
  }
}

.buttonRight {
  width: 209px;
  height: 46px;
  padding: 20px;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid #ffcb47;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  &:hover {
    background-color: #ffcb47;
    box-shadow: none;
    text-decoration: none;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportLarge) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportLarge) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportLarge) {
    margin-top: 24px;
  }
}
