@import '../../../styles/customMediaQueries.css';

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 48px;
    padding-top: 90px;
    max-width: 60%;
    margin: 0 auto;
  }
}

.filteredSearches {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.searchLink {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

@media (--viewportMedium) {
  .searchLink {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.searchName {
  color: var(--marketplaceColor);
}

.linkDataHolder {
  background: #ffcb471a;
  padding: 20px 20px 30px;

  @media (--viewportLarge) {
    padding: 60px 20px 40px;
  }
}

.linksHolder {
  flex: 0 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  text-align: center;

  @media (--viewportLarge) {
    flex: 0 0 calc(25% - 30px);
    max-width: calc(25% - 30px);
    text-align: center;
  }

  &:nth-child(2) {
    .linkButton {
      border: 1px solid #ffaeab;

      &:hover {
        background-color: #ffaeab;
      }
    }

    .iconHolder {
      background: #ffaeab;
    }

    .linkDataHolder {
      background: #ffaeab1a;
    }
  }

  &:nth-child(3) {
    .linkButton {
      border: 1px solid #ccebe5;

      &:hover {
        background-color: #ccebe5;
      }
    }
    .iconHolder {
      background: #ccebe5;
    }

    .linkDataHolder {
      background: #ccebe533;
    }
  }

  &:nth-child(4) {
    .linkButton {
      border: 1px solid #9191d6;

      &:hover {
        background-color: #9191d6;
      }
    }
    .iconHolder {
      background: #9191d6;
    }

    .linkDataHolder {
      background: #9191d61a;
    }
  }
}

.linkTitle {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 30px;
  }
}

.linkDescription {
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
  min-height: 105px;

  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 24px;
    min-height: 127px;
  }
}

.linkButton {
  background: transparent;
  border: 1px solid #ffcb47;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: var(--colorLightBlack);
  font-weight: 600;
  height: 28px;
  font-size: 10px;
  line-height: 14px;

  &:hover {
    text-decoration: none;
    /* opacity: 0.8; */
    background: #ffcb47;
  }

  @media (--viewportLarge) {
    height: 46px;
    line-height: 30px;
    font-size: 20px;
  }
}

.iconHolder {
  background: #ffcb47;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  top: 25px;

  @media (--viewportLarge) {
    width: 100px;
    height: 100px;
    top: 50px;
  }

  & img {
    width: 27px;

    @media (--viewportLarge) {
      width: auto;
    }
  }
}
