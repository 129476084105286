@import '../../../styles/customMediaQueries.css';

.root {

}

.rootTitle {
  width: 100%;
  margin: 30px auto 40px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 24px;
  line-height: 32px;

  @media (--viewportMedium) {
    margin: 130px auto 40px;
    line-height: 48px;
    font-size: 40px;
  }
}

.rootDescription {
  max-width: 1140px;
  font-size: 20px;
  line-height: 1.4;
  margin: 0 auto 65px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}


.adminInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.underline {
  text-decoration: underline;
  font-weight: 400;
}

.adminInfoIcon {
  flex-shrink: 0;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
  }

  @media (--viewportLarge) {
    margin-right: 65px;
  }
}

.adminInfoDescription {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0 0 40px;
    padding-left: 50px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.imgHolder {
  @media (--viewportMedium) {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

.image {
  width: 100%;
  max-width: 100%;
}

.list {
  padding: 30px 10px 0;
  @media (--viewportMedium) {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 0 0 20px;
  }
}

.listItem {
  margin-bottom: 20px;


  @media (--viewportLarge) {
    padding-left: 60px;
  }
}

.listItemTitle {
  font-size: 20px;
  line-height: 30px;
  justify-content: center;

  display: flex;
  align-items: center;

  text-align: center;
  font-weight: var(--fontWeightBold);

  & img {
    margin-right: 15px;
  }

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 30px;
    justify-content: flex-start;
    text-align: left;
  }
}

.listItemDescription {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;

  @media (--viewportLarge) {
    font-size: 16px;
    text-align: left;
    padding-left: 47px;
  }
}


.listIcon {
  display: none;

  @media (--viewportLarge) {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    background: #edabad;
    border-radius: 6px;
    margin-right: 7px;

    &:after {
      position: absolute;
      top: 3px;
      left: 15px;
      content: '';
      transform: rotate(45deg);
      height: 22px;
      width: 12px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
    }
  }
}

