@import '../../../styles/customMediaQueries.css';

.root {
}

.itemsWrapper {
  color: var(--colorLightBlack);
  flex-direction: column;
  background: #fcf9f4;
  display: block;
  margin: 10px;
  padding: 20px;
  border-radius: 6px;
  max-width: 360px;
  min-height: 345px;

  &:hover {
    text-decoration: none;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 6px;
}

.imageHolder {
  & img {
    max-width: 100%;
    width: 100%;
  }
}

.dataHolder {
}

.authorHolder {
}

.authorName {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 7px;
  display: inline-block;
}

.categoryHolder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.categoryLabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.categoryName {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 5px;
}

.priceHolder {
}

.price {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #323cb7;
}

.itemsCarouselHolder {
  & :global(.alice-carousel__next-btn),
  & :global(.alice-carousel__prev-btn) {
    display: inline-block;
    box-sizing: border-box;
    padding: 15px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    width: 32px;
    height: 32px;
    background: #9191d6;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: '';
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      background-image: url('./images/left.svg');
      background-position: center center;
      background-repeat: no-repeat;

      @media (--viewportLarge) {
        width: 40px;
        height: 40px;
      }
    }

    @media (--viewportLarge) {
      width: 40px;
      height: 40px;
    }
  }

  & :global(.alice-carousel__prev-btn) {
    left: -16px;
    @media (--viewportLarge) {
      left: -50px;
    }
  }

  & :global(.alice-carousel__next-btn) {
    right: -16px;
    @media (--viewportLarge) {
      right: -50px;
    }

    &:after {
      content: '';
      background-image: url('./images/right.svg');
    }
  }

  & :global(.alice-carousel__prev-btn-item.__inactive, .alice-carousel__next-btn-item.__inactive) {
    display: none;
  }

  & :global(.alice-carousel) {
    @media (--viewportLarge) {
      position: relative;
      width: 95%;
      margin: 0 auto;
    }
  }

  & :global(.alice-carousel__prev-btn [data-area]::after),
  & :global(.alice-carousel__next-btn [data-area]::after) {
    display: none;
  }
}

.rootTitle {
  font-size: 24px;
  line-height: 32px;
  max-width: 70%;
  margin: 0 auto;
  font-weight: 700;
  text-align: center;

  @media (--viewportLarge) {
    line-height: 48px;
    margin-bottom: 3px;
    font-size: 40px;
  }
}

.rootDescription {
  font-size: 15px;
  margin-top: 0;
  font-weight: 700;
  line-height: 30px;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.buttonHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.button {
  composes: buttonDefault from global;
  background-color: transparent;
  min-height: 37px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  max-width: 133px;
  border: 1px solid #ffcb47;

  &:hover {
    background-color: #ffcb47;
    text-decoration: none;
    box-shadow: none;
  }
}
