@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 85vh;
  padding: 0;
  background: #fcf9f4;

  @media (--viewportMedium) {
    min-height: 500px;
    height: calc(100vh - var(--topbarHeightDesktop));
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(90vh - var(--topbarHeightDesktop));
  }

  & div {
    & > h1 {
      width: 100%;
      max-width: 100%;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.2;
      text-align: left;
      color: rgba(50, 60, 183, 1);
      font-family: var(--fontFamily);

      & span > span:last-of-type {
        color: rgba(145, 145, 214, 1);
      }

      @media (--viewportLarge) {
        font-size: 68px;
        line-height: 70px;
      }
    }
  }
}

.heroRoot {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hero {
  flex-grow: 1;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
    justify-content: flex-end;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

.coloredSection {
  background: #fcf9f4;
}

.sectionWithBg {
  background: url('SectionJoin/images/join-bg.png') 0 0 no-repeat;
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1280px;
    padding: 0;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

.sectionContentNoMargin {
  margin: 0;

  @media (--viewportLarge) {
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}
